<template>
    <div class="tokens-container" :style="{opacity: isLoaded ? 1.0 : 0.0}">
        <CloseButton :close="close" />
        <div class="content">
            <button @click="handleChangeForm" class="toggler" :class="{selected: isForm}">
                {{ isForm ? "Назад" : "Создать токен" }}
            </button>
            <form v-if="isForm" @submit.prevent="handleSubmitNewToken">
                <input placeholder="Название компании" name="name" type="text">
                <textarea placeholder="Контакты" name="contacts"></textarea>
                <textarea placeholder="Текст рекламы" name="text"></textarea>
                <input type="submit" value="Сохранить">
                <div class="new-token-result" :class="{error: isNewTokenError}" v-html="newTokenResult"></div>
            </form>
            <table v-else>
                <thead>
                    <th>ID</th>
                    <th>Токен</th>
                    <th>ID заявки</th>
                    <th>Имя компании</th>
                    <th>Текст рекламы</th>
                    <th>Контакты</th>
                    <th>Создано</th>
                </thead>
                <tbody>
                    <tr v-for="token in tokens" :key="token.id">
                        <td>{{ token["id"] }}</td>
                        <td>{{ token["token"] }}</td>
                        <td>{{ token["application_id"] }}</td>
                        <td>{{ token["name"] }}</td>
                        <td>{{ token["text"] }}</td>
                        <td>{{ token["contacts"] }}</td>
                        <td>{{ formatDate(token["created_at"]) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { fetchTokens, createToken } from '../services/api';

    import CloseButton from './close-button.vue';

    export default {
        components: {
            CloseButton
        },
        props: ["close"],
        data() {
            return {
                tokens: [],
                isLoaded: false,
                isForm: false,
                newTokenResult: '',
                isNewTokenError: false,
                isNewTokenCreated: false,
            };
        },
        mounted() {
            this.loadTokens();
        },
        methods: {
            loadTokens() {
                fetchTokens().then(data => {
                    this.tokens = data;
                    this.isLoaded = true;
                });
            },
            handleSubmitNewToken(event) {
                this.isNewTokenError = false;
                this.isNewTokenCreated = false;
                this.newTokenResult = '';
                const name = event.target.name.value.trim();
                const contacts = event.target.contacts.value.trim();
                const text = event.target.text.value.trim();

                if (!name) {
                    this.isNewTokenError = false;
                    this.newTokenResult = 'Не введено имя компании!';
                    return;
                }

                if (!contacts) {
                    this.isNewTokenError = false;
                    this.newTokenResult = 'Не указаны контакты компании компании!';
                    return;
                }

                if (!text) {
                    this.isNewTokenError = false;
                    this.newTokenResult = 'Не указан текст рекламы!';
                    return;
                }

                createToken(name, contacts, text).then(data => {
                    if (data.success) {
                        this.newTokenResult = `Токен успешно создан!<br>${data.token}`;
                        this.isNewTokenCreated = true;
                    } else {
                        this.newTokenResult = 'Серверная ошибка';
                        this.isNewTokenError = true;
                    }
                });
            },
            formatDate(dateString) {
                const date = new Date(dateString);
                date.setMinutes(date.getMinutes() - (new Date()).getTimezoneOffset());
                const year = date.getFullYear();
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const day = ('0' + date.getDate()).slice(-2);
                const hours = ('0' + date.getHours()).slice(-2);
                const minutes = ('0' + date.getMinutes()).slice(-2);
                return `${year}-${month}-${day} ${hours}:${minutes}`;
            },
            handleChangeForm() {
                this.isForm = !this.isForm;
                if (this.isNewTokenCreated && !this.isForm) {
                    this.loadTokens();
                }

                this.newTokenResult = ``;
                this.isNewTokenCreated = false;
                this.isNewTokenError = false;
            }
        },
    };
</script>


<style scoped>
    .tokens-container {
        position: relative;
        background-color: white;
        border: 1px solid black;
        border-radius: 10px;
        padding: 25px;
        display: flex;
    }

    th, td {
        padding: 4px 8px;
    }

    td:nth-child(2) {
        font-size: 14px;
        word-break: break-all;
    }

    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    .content {
        overflow: auto;
        flex: 1;
    }

    table {
        overflow-x: auto;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-width: 60vw;
    }

    form input[type="text"],
    form textarea {
        margin: 12px 0;
        padding: 4px 6px;
        border-radius: 4px;
        font-size: 14px;
        width: 80%;
        border: 1px solid black;
    }

    form input[type="submit"] {
        background-color: #4CAF50;
        border: none;
        color: white;
        padding: 10px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        border-radius: 4px;
        margin-top: 3vh;
    }

    form input[type="submit"]:hover {
        opacity: 0.75;
    }

    .toggler {
        margin-bottom: 20px;
        background-color: beige;
        border: 2px outset rgb(121, 184, 192);
        outline: none;
        cursor: pointer;
        border-radius: 10px;
        padding: 3px 20px;
    }

    .toggler.selected {
        border: 2px inset rgb(4, 56, 63);
    }

    .toggler:hover {
        opacity: 0.75;
    }

    .toggler:active {
        background-color: rgb(240, 240, 180);
    }

    .new-token-result {
        font-size: 12px;
        margin-top: 10px;
    }

    .new-token-result.error {
        color: red;
    }
</style>