<template>
    <div class="container">
        <button class="help-btn" @click="isHelpVisible = !isHelpVisible">&quest;</button>
        <CloseButton :close="close" />
        <transition name="error" appear>
            <div class="error" v-if="isErrorVisible">
                Выбранный тариф невозможно применить к данному дню. Если вы хотите выбрать именно этот день, зарезервируйте слот, а не тариф. 
            </div>
        </transition>
        <div v-if="isHelpVisible" class="help-backdrop">
            <div class="help">
                <h3>Помощь</h3>
                <div class="description">
                    Здесь вы можете приобрести рекламу для телеграм канала python_tasks, зарезервировав 
                    временные слоты или тариф. Каждый временной слот длится 4 часа. Тариф - этот
                    набор последовательных слотов объдиненных вместе по более низкой цене, по сравнению с приобретением слотов независимо.
                </div>
                <h4>Подсветка дней в календаре</h4>
                <div class="highlight-body">
                    <div class="highlight-item">
                        <span class="color" style="background-color: gray"></span>
                        <span class="description"> - недоступно для резервирования</span>
                    </div>
                    <div class="highlight-item">
                        <span class="color" style="background-color: yellow"></span>
                        <span class="description"> - день частично занят</span>
                    </div>
                    <div class="highlight-item">
                        <span class="color" style="background-color: orange"></span>
                        <span class="description"> - доступно для резервирования (для тарифов)</span>
                    </div>
                    <div class="highlight-item">
                        <span class="color" style="background-color: white"></span>
                        <span class="description"> - будний день (доступно для резервирования)</span>
                    </div>
                    <div class="highlight-item">
                        <span class="color" style="background-color: rgb(255, 211, 205)"></span>
                        <span class="description"> - выходной день (доступно для резервирования)</span>
                    </div>
                </div>
                <div class="btn-wrapper">
                    <OutlinedButton
                        title="Понятно"
                        :on-click="hideHelp"
                    />
                </div>
            </div>
        </div>
        <div class="header">
            Выберите дату для публикации вашего поста на канале "Python_tasks"
        </div>
        <div class="body" @click.stop>
            <div class="calendar">
                <div class="move-actions">
                    <div class="move-action left" @click="moveMonth(-1)"></div>
                    <div class="move-action right" @click="moveMonth(1)"></div>
                </div>
                <div class="page">
                    <div class="title">
                        {{ title }}
                    </div>
                    <table @mousemove="handleMouseMove">
                        <thead>
                            <th></th>
                            <th>Понедельник</th>
                            <th>Вторник</th>
                            <th>Среда</th>
                            <th>Четверг</th>
                            <th>Пятница</th>
                            <th>Суббота</th>
                            <th>Воскресенье</th>
                            <th class="sm"></th>
                            <th class="sm">ПН</th>
                            <th class="sm">ВТ</th>
                            <th class="sm">СР</th>
                            <th class="sm">ЧТ</th>
                            <th class="sm">ПТ</th>
                            <th class="sm">СБ</th>
                            <th class="sm">ВС</th>
                        </thead>
                        <tbody>
                            <tr v-for="row_index in 6" :key="row_index - 1" :data-index="row_index - 1">
                                <td class="week-number">
                                    <span>{{ _buildWeekNumber(row_index - 1) }}</span>
                                </td>
                                <td 
                                    v-for="col_index in 7"
                                    class="week-day"
                                    :key="`${col_index - 1}-${month}-${year}`"
                                    :data-index="col_index - 1"
                                    :set="dayNumber = _buildDayNumber(row_index - 1, col_index - 1)"
                                >
                                    <div
                                        :data-number="dayNumber"
                                        :data-coords="`${row_index - 1}${col_index - 1}`"
                                        :class="{weekend: col_index > 5 && dayNumber !== '', [_getDateStatus(dayNumber)]: true}"
                                        @click="handleDayClick"
                                    >
                                        {{ dayNumber }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import OutlinedButton from './outlined-button';
    import CloseButton from './close-button.vue';
    const monthNames = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
    ];

    export default {
        components: {
            OutlinedButton,
            CloseButton
        },
        props: ['occupied-slots', 'slot-schemes', 'close', 'choose', 'tariff'],
        data() {
            const now = new Date();
            return {
                nowday: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
                month: now.getMonth(),
                year: now.getFullYear(),
                tariffDays: {},
                currentTariffCells: [],
                errorTimer: null,
                isErrorVisible: false,
                isHelpVisible: false,
            };
        },
        mounted() {
            this._calculateTariffDays();
            if (localStorage.getItem('calendar_help')) {
                this.isHelpVisible = false;
            } else {
                this.isHelpVisible = true;
                localStorage.setItem('calendar_help', '1');
            }
        },
        methods: {
            _calculateTariffDays() {
                if (this.tariff === null) {
                    return;
                }
                const tariff = this.tariff;
                const occupiedSlots = this.occupiedSlots;
                this.tariffDays = {};
                const tariffDays = this.tariffDays;

                const dayAmount = Math.ceil((+tariff["start_slot"] - 1 + +tariff["slot_amount"]) / 6);


                const startWeekDayIndex = ((new Date(this.year, this.month, 1)).getDay() || 7) - 1;

                for (let i = 0; i < 6; i++) {
                    let startWeekDay, finishWeekDay;
                    if (tariff['start_day'] === null) {
                        startWeekDay = 0;
                        finishWeekDay = 7;
                    } else {
                        // если тариф привязан к дню, то нужно рассматривать только этот (начальный) день
                        startWeekDay = tariff['start_day'];
                        finishWeekDay = tariff['start_day'] + 1; //Math.min(tariff['start_day'] + Math.ceil(tariff['slot_amount'] / 6), 7);
                    }

                    for (let j = startWeekDay; j < finishWeekDay; j++) {
                        const days = [];
                        for (let k = 0; k < dayAmount; k++) {
                            const d = new Date(this.year, this.month, i * 7 + j + k - startWeekDayIndex + 1);
                            if (d <= this.nowday) {
                                days.length = 0;
                                break;
                            }
                            
                            const date = (new Date(d.getTime() - d.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
                            if (occupiedSlots[date] && occupiedSlots[date].length) {
                                let isOccupied = true;
                            
                                // условия того, чтобы начальная (cond1) или конечная (cond2) клетки были допустимы
                                const cond1 = k === 0 && tariff["start_slot"] > Math.max(...occupiedSlots[date]);
                                const _c2 = (tariff["start_slot"] - 1 + tariff["slot_amount"]);
                                const cond2 = k === dayAmount - 1 && _c2 < Math.min(...occupiedSlots[date]) && _c2 !== 0;

                              
                                if (cond1 || cond2) {
                                    isOccupied = false;
                                }
                                if (isOccupied) {
                                    days.length = 0;
                                    break;
                                }
                            }
                            days.push(`${i + Math.floor((j + k) / 7)}${(j + k) % 7}`);
                        }

                        for (let day of days) {
                            tariffDays[day] = days;
                        }
                    }
                }
            },
            _getDateStatus(dayNumber) {
                if (!dayNumber) {
                    return "";
                }
                const nowday = this.nowday;
                if (dayNumber <= nowday.getDate() && this.month <= this.nowday.getMonth() && this.year <= this.nowday.getFullYear()) {
                    return "disabled";
                }

                const date = new Date(this.year, this.month, dayNumber, 1);
                const _date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

                const dateString = _date.toISOString().split('T')[0];

                if (this.occupiedSlots[dateString] && this.occupiedSlots[dateString].length === this.slotSchemes.length) {
                    return 'disabled';
                }

                if (this.occupiedSlots[dateString] && this.occupiedSlots[dateString].length > 0) {
                    return 'partially';
                }

                return "";
            },
            _buildWeekNumber(i) {
                const firstJanuaryDate = new Date(this.year, 0, 1);
                const firstMonthDate = new Date(this.year, this.month, 1);
                const weeksLast = Math.ceil((firstMonthDate - firstJanuaryDate) / (1000 * 3600 * 24 * 7));
                const weeknumber = weeksLast + i + 1;
                return weeknumber;
            },
            _buildDayNumber(i, j) {
                const monthStartDate = new Date(this.year, this.month, 1);
                let monthFirstDay = monthStartDate.getDay();
                if (monthFirstDay === 0) {
                    monthFirstDay = 7;
                }
                monthFirstDay--;

                const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

                const number = 7 * i + j - monthFirstDay + 1;
                if (number <=0 || number > daysInMonth) {
                    return '';
                }
                return number.toString();
            },
            moveMonth(value) {
                const d = new Date(this.year, this.month + value);
                const nowMonth = new Date(this.nowday.getFullYear(), this.nowday.getMonth(), 1);
                if (d < nowMonth || Math.abs(d.getMonth() - nowMonth.getMonth()) >= 2 && !(d.getMonth() === 0 && nowMonth.getMonth() === 11)) {
                    return;
                }
                this.month = d.getMonth();
                this.year = d.getFullYear();
                this._calculateTariffDays();
            },
            handleDayClick(event) {
                const target = event.target;
                if (target.classList.contains('disabled')) {
                    return;
                }

                if (isNaN(parseInt(target.dataset.number))) {
                    return;
                }


                let data = {};
                const dayNumber = parseInt(event.target.dataset.number);
                if (this.tariff === null) {
                    data = {
                        useTariff: false,
                        dayNumber: dayNumber,
                        month: this.month,
                        year: this.year,
                    };
                } else {
                    const coords = event.target.dataset.coords;
                    const days = this.tariffDays[coords];

                    if (!days) {
                        this.isErrorVisible = true;
                        if (this.errorTimer) {
                            clearTimeout(this.errorTimer);
                        }
                        this.errorTimer = setTimeout(() => {
                            this.isErrorVisible = false;
                        }, 2500);
                        return;
                    }

                    const ind = days.indexOf(coords);
                    if (ind < 0) {
                        return;
                    }
                    const startDate = new Date(this.year, this.month, dayNumber - ind);

                    const startDateTime = new Date(
                        this.year,
                        this.month,
                        dayNumber - ind,
                        (+this.tariff["start_slot"] - 1) * 4,
                        0,
                        0
                    );

                    const finishDateTime = new Date(
                        this.year,
                        this.month,
                        dayNumber - ind,
                        (+this.tariff["start_slot"] + +this.tariff["slot_amount"] - 1) * 4 - 1,
                        59,
                        59
                    );

                    data = {
                        useTariff: true,
                        tariff: this.tariff,
                        dayNumber: dayNumber,
                        //coords: coords,
                        startDate: startDate,
                        startDateTime: startDateTime,
                        finishDateTime: finishDateTime,
                    };
                }

                this.choose(data);
            },
            handleMouseMove(event) {
                if (this.tariff === null) return;
                const target = event.target;
                const table = event.currentTarget;
                if (target.nodeName.toUpperCase() !== 'DIV') return;

                const cellIndex = +target.parentNode.dataset.index;
                const rowIndex = +target.parentNode.parentNode.dataset.index;
                const cells = this.tariffDays[`${rowIndex}${cellIndex}`];

                if (cells !== this.currentTariffCells) {
                    for (let cell of this.currentTariffCells) {
                        const rowIndex = +cell[0];
                        const cellIndex = +cell[1];
                        if (table.rows[rowIndex] && table.rows[rowIndex].cells[cellIndex + 1]) {
                            table.rows[rowIndex].cells[cellIndex + 1].classList.remove('tariff-cell');
                        }
                    }

                    this.currentTariffCells = [];

                    if (cells) {
                        for (let cell of cells) {
                            const rowIndex = +cell[0];
                            const cellIndex = +cell[1];
                            if (table.rows[rowIndex] && table.rows[rowIndex].cells[cellIndex + 1]) {
                                table.rows[rowIndex].cells[cellIndex + 1].classList.add('tariff-cell');
                            }
                        }
                        this.currentTariffCells = cells;
                    }
                }
            },
            hideHelp() {
                this.isHelpVisible = false;
            },
        },
        computed: {
            title() {
                return `${monthNames[this.month].toUpperCase()} ${this.year.toString().toUpperCase()}`;
            }
        }
    };
</script>

<style scoped>
    .container {
        position: relative;
        background-color: rgb(249, 243, 231);
        border-radius: 4px;
        border: 1px solid gray;
        box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.25);
    }

    .container > .header {
        color: rgb(46, 63, 74);
        font-size: 20px;
        margin: 20px 0;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .container > .body {
        padding: 15px;
        box-sizing: border-box;
    }

    .calendar {
        position: relative;
        border: 1px solid black;
        padding: 10px 5px 25px 5px;
        background-color: white;
    }

    .calendar .page {
        border: 3px solid rgb(209, 209, 209);
        overflow: hidden;
    }

    .calendar .page .title {
        font-size: 14px;
        font-weight: 600;
        color: black;
        border: 2px solid rgb(209, 209, 209);
        padding: 12px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        border-left-width: 0;
        border-right-width: 0;
        border-top-width: 0;
    }

    .page table {
        border-collapse: collapse;
    }

    .page table th {
        font-size: 11px;
        padding: 8px 0;
    }

    .page table th.sm {
        display: none;
    }

    .page table tr {
        border: 3px solid rgb(209, 209, 209);
        border-left-width: 0;
        border-right-width: 0;
    }

    .page table td {
        border: 2px solid rgb(209, 209, 209);
        min-width: 90px;
        height: 60px;
        text-align: left;
        vertical-align: top;
        box-sizing: border-box;
        padding: 0;
        position: relative;
    }

    .page table td:first-child {
        min-width: 70px;
        max-width: 70px;
    }

    .page table td div {
        width: 100%;
        padding: 5px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        user-select: none;
    }

    .page table td div.weekend {
        background-color: rgb(255, 211, 205);
    }

    .page table td div:hover,
    .page table td div:hover.weekend {
        background-color: rgb(192, 233, 226);
    }

    .page table td div.disabled,
    .page table td div.disabled:hover {
        background-color: gray;
    }

    .page table td div.partially,
    .page table td div.partially:hover {
        background-color: yellow;
    }

    .page table td.tariff-cell div {
        background-color: orange !important;
    }

    /*.page table td div.booked {
        background-color: rgb(209, 209, 209);
    }*/

    .page table td:first-child {
        border-left-width: 0;
    }

    .page table td:last-child {
        border-right-width: 0;
    }

    .page table td.week-number {
        width: 5vw;
        text-align: center;
        font-size: 11px;
        color: rgb(162, 162, 162);
        padding-top: 1vh;
    }

    .page table td.week-day {
        font-size: 14px;
        color: black;
    }

    .page table tr:last-child,
    .page table tr:last-child td {
        border-bottom-width: 0;
    }

    .calendar .move-actions {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        z-index: 10;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        pointer-events: none;
    }

    .calendar .move-actions .move-action {
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 1.0));
        cursor: pointer;
        pointer-events: auto;
    }

    .calendar .move-actions .move-action.left {
        width: 0; 
        height: 0; 
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent; 
        border-right: 15px solid white; 
    }

    .calendar .move-actions .move-action.right {
        width: 0; 
        height: 0; 
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 15px solid white;

    }

    .help-btn {
        position: absolute;
        top: -30px;
        right: 30px;
        font-size: 20px;
        background-color: transparent;
        border-width: 0;
        outline: none;
        color: white;
        cursor: pointer;
    }

    .help-btn:hover {
        opacity: 0.5;
    }

    @media (max-width: 800px) {
        .page table th {
            font-size: 12px;
        }

        .page table th.sm {
            display: table-cell;
        }

        .page table th:not(.sm) {
            display: none;
        }

        .page table tr {
            min-width: 100%;
        }

        .page table td {
            min-width: 0;
            width: 14vw;
            height: 55px;
        }

        .page table td:first-child {
            min-width: 25px;
            max-width: 25px;
        }
    }

    @media (max-width: 500px) {
        .page table td {
            height: 50px;
        }
    }

    .error {
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 5%;
        background-color: rgb(243, 61, 61);
        z-index: 10;
        margin: 0 5vw;
        color: rgb(6, 6, 59);
        border-radius: 10px;
        padding: 10px 20px;
        font-size: 14px;
    }

    .error-enter-active,
    .error-leave-active {
        transition: opacity .6s;
    }

    .error-enter-from,
    .error-leave-to {
        opacity: 0;
    }

    .help-backdrop {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.85);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .help-backdrop .help {
        width: 75%;
        background-color: white;
        padding: 20px;
        border: 1px solid black;
        border-radius: 10px;
        box-sizing: border-box;
        max-height: 98%;
        overflow-y: auto;
    }

    @media (max-width: 500px) {
        .help-backdrop .help {
            padding: 10px;
            width: 90%;
        }
        
    }

    .help .description {
        text-align: left;
        text-indent: 40px;
        margin: 0 10px;
    }

    .help .highlight-body {
        text-align: left;
        margin: 0 5vw;
    }
    .help .highlight-item {
        margin: 4px 0;
        line-height: 16px;
        box-sizing: border-box;
    }
    .help .highlight-item .color {
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 1px solid black;
        box-sizing: border-box;
        position: relative;
        top: 3px;
    }
    /*.help .highlight-item .description {}*/

    .help .btn-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        margin: 0 auto;
        width: 150px;
        text-align: center;
        padding: 0 auto;
        margin-top: 20px;
    }

</style>