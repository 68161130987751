<template>
    <div class="container">
        <div v-if="userTgId === null" class="login">
            <button @click="showAuthForm">Авторизация</button>
        </div>
        <div v-else class="logout">
            <div class="user-tg-id">
                ID: {{ userTgId }}
            </div>
            <button @click="logout">Выйти из аккаунта</button>
        </div>

    </div>    
</template>

<script>
    import { getCookie, deleteCookie } from '@/services/cookie';
    import { logout } from '../services/api';

    export default {
        props: ["is-auth-form-visible", "show-auth-form"],
        data() {
            return {
                userTgId: null
            };
        },
        created() {
            this._updateTgId();
        },
        methods: {
            _updateTgId() {
                this.userTgId = getCookie('user_tg_id') || null;
            },
            async logout() {
                const response = await logout();
                if (response['success']) {
                    this.userTgId = null;
                    deleteCookie('user_tg_id');
                }
                this.$emit('logout');
            }
        },
        watch: {
            isAuthFormVisible(newValue, oldValue) {
                if (newValue !== oldValue && newValue === false) {
                    this._updateTgId();
                }
            }

        }
    };
</script>

<style scoped>
    .container {
        margin-top: 20px;
        display: inline-block;
    }

    button, .user-tg-id {
        border: 1px solid rgb(110, 119, 125);
        padding: 3px 25px;
        color: rgb(41, 56, 69);
        border-radius: 4px;
        background-color: white;
        font-size: 15px;
        font-weight: 500;
        margin: 4px 0;
    }

    button {
        cursor: pointer;
        position: relative;
    }

    button:hover {
        background-color: black;
        color: #F0F0F0;
    }

    .login button::after {
        display: none;
        content: "Авторизация позволяет сохранять данные для следующих бронирований слотов, а также возможность использовать эти данные на разных устройствах.";
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translate(-50%, -100%);
        background-color: white;
        border: 1px solid black;
        padding: 10px;
        border-radius: 10px;
        pointer-events: none;
        color: black;
        min-width: 100%;
        width: 300%;
        max-width: 90vw;
        font-size: 16px;
    }

    .login button:hover::after {
        display: block;
    }
</style>