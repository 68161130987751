<template>
    <div class="container">
        <CloseButton :close="close"/>
        <div class="title">
            Пожалуйста, заполните форму для оплаты
        </div>
        <div class="form-container">
            <form @submit.prevent="handleSubmit">
                <label style="display: none;">
                    <span>Form Id</span>
                    <input type="text" name="form_id" :value="userFormData.id || null">
                </label>
                <label>
                    <span>Ник в телеграмм<span class="star">*</span></span>
                    <input type="text" name="username" v-model="username" required>
                </label>
                <label>
                    <span>Ссылка на рекламируемый канал (сайт)<span class="star">*</span></span>
                    <input type="text" name="link" v-model="link" required>
                </label>
                <label>
                    <span>Правовой статус плательщика<span class="star">*</span></span>
                    <select name="status" v-model="status" required>
                        <option value="legal_entiry">ООО/ОАО/ЗАО</option>
                        <option value="individual_entrepreneur">ИП</option>
                        <option value="self_employed">Самозанятый</option>
                        <option value="natural_person">Физ. Лицо</option>
                    </select>
                </label>
                <label>
                    <span>Название Организации/ИП/ФИО<span class="star">*</span></span>
                    <input type="text" name="name" v-model="name" required>
                </label>

                <label>
                    <span>E-mail<span class="star">*</span></span>
                    <input type="email" name="email" v-model="email" required>
                </label>
                <div class="spacer"></div>

                <label class="consent">
                    <input type="checkbox" v-model="consent">
                    <span>Согласен на обработку персональных данных, а также ознакомился с <a href="/static/docs/terms_of_service.pdf" target="_blank">"Правилами и условиями предоставления рекламных услуг в Telegram-канале «Задачи по Python и машинному обучению»"</a></span>
                </label>

                <div>
                    <input
                        type="submit"
                        value="ПРОИЗВЕСТИ ОПЛАТУ СЛОТА"
                        :disabled="!consent"
                        :style="{opacity: consent ? 1.0 : 0.5, pointerEvents: consent ? 'all' : 'none'}"
                    >
                </div>

                <div v-if="isError" class="error">
                    Что-то пошло не так...
                </div>
                
            </form>
        </div>
    </div>
</template>

<script>
    import { sendUserFormData } from '../services/api';
    import CloseButton from './close-button.vue';

    export default {
        components: {
            CloseButton
        },
        props: ['book-date', 'book-slot', 'on-submit', 'close', 'user-form-data'],
        data() {
            return {
                isError: false,
                username: this.userFormData.username || "",
                link: this.userFormData.link || "",
                status: this.userFormData.status,
                name: this.userFormData.name,
                email: this.userFormData.email,
                consent: false,
            };
        },
        methods: {
            async handleSubmit(event) {
                this.isError = false;
                const fd = new FormData(event.target);

                try {
                    const data = await sendUserFormData(fd);
                    if (data.success) {
                        this.onSubmit(data.id);
                    } else {
                        throw new Error('Серверная ошибка');
                    }
                } catch(error) {
                    console.error(error.message);
                    this.isError = true;
                    return;
                }
            }
        }
    };
</script>

<style scoped>
    .container {
        position: relative;
        background-color: rgb(249, 241, 230);
        border-radius: 4px;
        border: 1px solid white;
        padding: 18px;
        min-width: 60vw;
        box-sizing: border-box;
        padding-top: 0;
    }

    .title {
        font-size: 20px;
        margin: 24px 0;
        font-size: 20px;
        font-weight: 700;
        color: rgb(41, 56, 68);
        letter-spacing: 0.5px;
    }

    .form-container {
        background-color: rgb(40, 56, 68);
        width: 100%;
        border-radius: 4px;
        margin: 0 auto;
        padding: 15px 0;
        box-sizing: border-box;
    }

    form {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: space-between;
        color: white;
        padding: 15px;
        overflow: hidden;
        border: 1px solid white;
        border-radius: 6px;
        width: fit-content;
        box-sizing: border-box;
    }

    form label {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    form label span {
        display: inline-block;
        position: relative;
        font-size: 12px;
        bottom: 3px;
    }

    form label > input,
    form label > select {
        width: 300px;
        height: 30px;
        border-width: 0;
        border-radius: 4px;
        outline: none;
        margin: 0;
        box-sizing: border-box;
    }

    form label.consent {
        display: flex;
        flex-direction: row;
        color: rgb(250, 250, 50);    
        justify-content: flex-start;
        align-items: flex-start;
        cursor: pointer;
    }

    form label.consent > input[type="checkbox"] {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        cursor: pointer;
    }

    form label.consent span {
        bottom: 1px;
        text-align: left;
        display: block;
        width: 280px;
    }

    form label.consent span a {
        color: rgb(216, 226, 173);
        text-decoration: none;
    }

    @media (max-width: 450px) {
        form label > input,
        form label > select {
            max-width: 76vw;
        }

        form label.consent span {
            max-width: 65vw;
        }
    }

    form input[type="submit"] {
        border: 1px solid white;
        border-radius: 4px;
        background-color: transparent;
        padding: 4px 0;
        width: 100%;
        color: white;
        font-size: 16px;
        cursor: pointer;
        margin-top: 8px;
    }

    form input[type="submit"]:hover {
        background-color: white;
        color: rgb(40, 56, 68);
    }

    .spacer {
        height: 4px;
    }

    .close-btn {
        position: absolute;
        top: -30px;
        right: 5px;
        font-size: 20px;
        background-color: transparent;
        border-width: 0;
        outline: none;
        color: white;
        cursor: pointer;
    }

    .close-btn:hover {
        opacity: 0.5;
    }

    .error {
        color: rgb(57, 156, 11);
        margin-top: 5px;
    }

    .star {
        color: red;
    }
</style>