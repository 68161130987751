<template>
    <div class="container" @click.stop="">
        <CloseButton :close="close" />
        <h3>Помощь</h3>
        <p>Административная таблица подкачивается автоматически, после заполнения клиентом всех форм на сайте payment-it.ru
        и осуществления оплаты клиентом.</p>
        
        <p>
            Действия администратора:</p>
            <p>1) В  течение 24 часов осуществить модерацию рекламных ссобщений, 
                поступивших в телеграм канал @advertmng. Зайти в раздел “Токены”. Нажать кнопку
                “Создать токен”.  Заполнить поля формы, нажать кнопку «Сохранить». Скопировать
                Созданный токен, выслать его клиенту.
            </p>
            <p>2) Получить информацию о забронированном рекламном слоте из таблицы заявок кабинета администратора. 
                Идентификатор заявки можно получить 
                от клиента или же найти из таблицы токенов по выданному ранее токену клиенту.
                Убедиться в оплате рекламного заказа (в графе "Оплачено" должно стоять "Да").
            </p>
            <p>3) Получив от клиента сообщение о бронировании рекламы и ее оплате (с указанием идентификатора/номера заказа), разместить рекламное объявление в ТГ канале задач Python&ML. 
                Если клиент оплатил, но не связался, 
                то самостоятельно обратиться к клиенту, подтвердив выход рекламы в ТГ канале 
                и разместить рекламный баннер (через бот).</p>
            <p>4) Ссылку на рекламное объявление админ размещает в колонке «Ссылка на пост в Телеграме» в специальном окне.</p>
            <p>5) Админ меняет статус слота в колонке «Статус заявки»
                <ul>
                    <li>размещено в бот</li>
                    <li>реклама вышла</li>
                    <li>рекламу можно удалить</li>
                    <li>удалено</li>
                    <li>отменено</li>
                </ul>
            </p>
        <p>
            Админ должен нажать кнопку «Сохранить» после всех изменений (для каждой заявке отдельно) в административной части сайта.
        </p>
        <p>
            6) ЕСЛИ клиент забронировал слот, не оплатил (!!!) и хочет отменить свою заявку на рекламу полностью, то ЭТО ВОЗМОЖНО.
            Админ может отменить заявку на рекламу выбрав вариант «отмена» в колонке «Статус заявки» ИЛИ 
            Удалить заявку нажав кнопку «Удалить» в крайнем правом столбце.
            Сохранить информацию, нажав кнопку «Сохранить».
        </p>
        <p>
            7) ЕСЛИ клиент забронировал рекламный слот, оплатил (!!!) и хочет отменить свою заявку на рекламу полностью, то ЭТО НЕВОЗМОЖНО, можно лишь перенести, и УЖЕ ОПЛАЧЕННЫЙ слот заменить на другой рекламный слот.<br>
        </p>
        <p>
            Процедура замены слотов следующая:
            <ul>
                <li>выбрать «отмена» в колонке «Статус заявки»</li>
                <li>выбрать новый рекламный слот (заведённый через страницу сайта payment-it.ru)</li>
                <li>колонка «Ссылка на отменённую заявку»;</li>
                <li>нажать кнопку «Выбрать» и далее кликнуть по той заявке, которая была отменена.</li>
            </ul>
        </p>
        <p>
            Программа автоматически отобразит номер id отменённой заявки (у нового слота), связав старый отменённый рекламный слот с действующим.
            Сохранить информацию, нажав кнопку «Сохранить».
        </p>
        <p>
            8) Как завести взаимный пиар (ВП) через сайт и административную часть сайта:
            -выбрать рекламный слот (заведённый через страницу сайта payment-it.ru);
            -в колонке ВП заменить статус с «Нет» на «Да»;
            -сохранить информацию, нажав кнопку «Сохранить».
        </p>
    </div>
</template>

<script>
    import CloseButton from './close-button.vue';

    export default {
        components: {
            CloseButton
        },
        props: ["close"]
    }
</script>

<style scoped>
    .container {
        position: relative;
        width: 60%;
        height: 80%;
        border-radius: 20px;
        border: 1px solid black;
        padding: 20px;
        background-color: white;
        text-align: left;
        margin: 0 auto;
    }

    h3 {
        text-align: center;
    }

    p {
        text-indent: 30px;
    }
</style>