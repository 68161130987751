<template>
    <div class="container">
        <HomeLink />
        <h2>Контактные данные</h2>
        <div class="requisites">
            <span>ИП Прокопьев Сергей Олегович</span>
            <span>ИНН 774300959712</span>
            <span>ОГРНИП 315774600284642</span>
            <span>Р/с № 40802810038000026843</span>
            <span>Банк ПАО Сбербанк</span>
            <span>К/с № 30101810400000000225</span>
            <span>БИК 044525225</span>
            <span>Е-mail: so.prokopiev@gmail.com</span>
        </div>
    </div>
</template>

<script>
    import HomeLink from '../home-link';

    export default {
        components: {
            HomeLink
        }
    }
</script>

<style scoped>
    .requisites {
        display: flex;
        flex-direction: column;
        max-width: 280px;
        text-align: left;
        margin: 150px auto;
        font-size: 18px;
    }

    .requisites > span:nth-child(1) {
        font-weight: 700;
    }
</style>