<template>
    <div class="main-screen-container">
        <button class="btn-help" @click="isHelpVisible = true">
            <svg 
                id="Capa_1"
                style="enable-background:new 0 0 91.999 92;"
                version="1.1"
                viewBox="0 0 91.999 92"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <path fill=rgb(138,149,151) d="M45.385,0.004C19.982,0.344-0.334,21.215,0.004,46.619c0.34,25.393,21.209,45.715,46.611,45.377  c25.398-0.342,45.718-21.213,45.38-46.615C91.655,19.986,70.785-0.335,45.385,0.004z M45.249,74l-0.254-0.004  c-3.912-0.116-6.67-2.998-6.559-6.852c0.109-3.788,2.934-6.538,6.717-6.538l0.227,0.004c4.021,0.119,6.748,2.972,6.635,6.937  C51.903,71.346,49.122,74,45.249,74z M61.704,41.341c-0.92,1.307-2.943,2.93-5.492,4.916l-2.807,1.938  c-1.541,1.198-2.471,2.325-2.82,3.434c-0.275,0.873-0.41,1.104-0.434,2.88l-0.004,0.451H39.429l0.031-0.907  c0.131-3.728,0.223-5.921,1.768-7.733c2.424-2.846,7.771-6.289,7.998-6.435c0.766-0.577,1.412-1.234,1.893-1.936  c1.125-1.551,1.623-2.772,1.623-3.972c0-1.665-0.494-3.205-1.471-4.576c-0.939-1.323-2.723-1.993-5.303-1.993  c-2.559,0-4.311,0.812-5.359,2.478c-1.078,1.713-1.623,3.512-1.623,5.35v0.457H27.935l0.02-0.477  c0.285-6.769,2.701-11.643,7.178-14.487C37.946,18.918,41.446,18,45.53,18c5.346,0,9.859,1.299,13.412,3.861  c3.6,2.596,5.426,6.484,5.426,11.556C64.368,36.254,63.472,38.919,61.704,41.341z"/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/>
            </svg>
        </button>
        <div class="img">
            <img 
                src="@/assets/images/logo/logo-resized.png"
                alt="логотип"
            >
        </div>
        
        <AuthState
            :is-auth-form-visible="isAuthFormVisible"
            :show-auth-form="handleShowAuthForm"
            @logout="handleLogout"
        />

        <div v-if="!isGratitude" class="base">
            <div class="info-text" data-number="first">
                ВАРИАНТЫ РАЗМЕЩЕНИЯ РЕКЛАМЫ
            </div>

            <div class="info-text" data-number="second">
                ВРЕМЕННЫЕ СЛОТЫ
            </div>

            <div class="standard">
                <TariffCard
                    title="standard"
                    :price="slotPrice"
                    :slot-amount="1"
                    :feed-hours-amount="96"
                    :is-standard="true"
                    @tariff-pay="showCalendar"
                />
            </div>

            <div class="info-text" data-number="third">
                ДОПОЛНИТЕЛЬНЫЕ ТАРИФЫ<br>
                (ПАКЕТЫ СЛОТОВ)
            </div>

            <CardsPanel
                :tariffs="tariffs"
                @tariff-pay="showCalendar"
            />
        </div>
        
        <PayGratitude
            v-if="isGratitude"
        />


        <ModalBackdrop v-if="isCalendarVisible">
            <PayCalendar
                :occupied-slots="occupiedSlots"
                :slot-schemes="slotSchemes"
                :tariffs="tariffs"
                :tariff="tariff"
                :choose="handleChooseDate"
                :close="handleClose"
            />
        </ModalBackdrop>
        
        <ModalBackdrop v-if="isBookSlotsVisible">
            <BookSlots
                :date="choosenDate"
                :occupied-slots="occupiedSlots"
                :slot-schemes="slotSchemes"
                :choose="handleChooseSlot"
                :close="handleClose"
            />
        </ModalBackdrop>

        <ModalBackdrop v-if="isUserFormVisible">
            <UserForm
                :book-date="choosenDate"
                :book-slot="choosenSlot"
                :user-form-data="userFormData"
                :on-submit="handleSubmitUserForm"
                :close="handleClose"
            />
        </ModalBackdrop>

        <ModalBackdrop v-if="isBookConfirmation">
            <BookConfirmation
                :choosen-date="choosenDate"
                :choosen-slot="choosenSlot"
                :choosen-tariff-data="choosenTariffData"
                @confirm="handleBookConfirm"
                :close="handleClose"
            />
        </ModalBackdrop>

        <ModalBackdrop v-if="isAuthFormVisible">
            <AuthForm
                :close="handleClose"
                @login="handleLogin"
            />
        </ModalBackdrop>
        <ModalBackdrop v-if="mustPayApp !== null">
            <MustPayForm
                :app="mustPayApp"
                @close="mustPayApp = null"
            />
        </ModalBackdrop>
        <ModalBackdrop v-if="isHelpVisible">
            <HelpInfo
                :close="handleClose"
            />
        </ModalBackdrop>
        <CookieAlert/>
    </div>
</template>

<script>
    import ModalBackdrop from '../modal-backdrop.vue';
    import CardsPanel from '../cards-panel.vue';
    import PayCalendar from '../pay-calendar.vue';
    import BookSlots from '../book-slots.vue';
    import UserForm from '../user-form.vue';
    import PayGratitude from '../pay-gratitude.vue';
    import TariffCard from '../tariff-card.vue';
    import BookConfirmation from '../book-сonfirmation.vue';
    import AuthForm from '../auth-form.vue';
    import AuthState from '../auth-state.vue';
    import MustPayForm from '../must-pay-form.vue';
    import HelpInfo from '../help-info.vue';
    import CookieAlert from '../cookie-alert.vue';

    import { 
        bookTimeSlot,
        bookTariff,
        activateSession,
        fetchTariffs,
        fetchSlotSchemes,
        fetchOccupiedSlots,
        fetchFormData,
        fetchApplicationData,
    } from '../../services/api';
    import { deleteCookie, getCookie, setCookie } from '../../services/cookie';

    export default {
        components: {
            ModalBackdrop,
            CardsPanel,
            PayCalendar,
            BookSlots,
            UserForm,
            PayGratitude,
            TariffCard,
            BookConfirmation,
            AuthForm,
            AuthState,
            MustPayForm,
            HelpInfo,
            CookieAlert,
        },
        data() {
            return {
                isGratitude: location.search.includes('is_payed=1'),
                isCalendarVisible: false,
                isBookSlotsVisible: false,
                isUserFormVisible: false,
                isBookConfirmation: false,
                isAuthFormVisible: false,
                isHelpVisible: false,
                choosenDate: null,
                choosenSlot: null,
                slotSchemes: [],
                occupiedSlots: {},
                tariffs: [],
                tariff: null,
                choosenTariffData: null,
                userFormData: {},
                mustPayApp: null,
                slotPrice: '',
                token: ''
            };
        },
        async mounted() {
            const currentSID = getCookie('sid');
            const data = await activateSession(currentSID);
            const sid = data['sid'];
            if (sid) {
                setCookie('sid', sid);
            }

            if (!this.isGratitude) {
                this._checkLastPayed();
                this._fetchTariffs();
                this._fetchSlotSchemes();
                this._fetchOccupiedSlots();
                this._fetchFormData();
            }
        },
        methods: {
            async _checkLastPayed() {
                let isSuccess = false;
                if (getCookie('user_tg_id')) {
                    const data = await fetchApplicationData('-1');
                    if (data.id) {
                        isSuccess = true;
                        if (!data["is_payed"] && !data['is_interchange']) {
                            setCookie('must_pay', data['id']);
                            this.mustPayApp = data;
                        } else {
                            deleteCookie('must_pay');
                        }
                    }
                }

                if (!isSuccess) {
                    const mustPay = getCookie('must_pay');
                    if (mustPay) {
                        fetchApplicationData(+mustPay).then(data => {
                            if (data["is_payed"]) {
                                deleteCookie('must_pay');
                            } else {
                                if (data['price']) {
                                    this.mustPayApp = data;
                                }
                            }
                        });
                    }
                }
            },
            _fetchFormData() {
                return fetchFormData().then(data => {
                    this.userFormData = data;
                });
            },
            _fetchTariffs() {
                return fetchTariffs().then(data => {
                    this.tariffs = data;
                });
            },
            _fetchSlotSchemes() {
                return fetchSlotSchemes().then(data => {
                    this.slotSchemes = data;
                    let minPrice = Infinity, maxPrice = -1;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].price < minPrice) {
                            minPrice = data[i].price;
                        }
                        if (data[i].price > maxPrice) {
                            maxPrice = data[i].price;
                        }
                    }
                    //this.slotPrice = `${minPrice} - ${maxPrice}`;
                    this.slotPrice = `от ${minPrice}`;
                });
            },
            _fetchOccupiedSlots() {
                return fetchOccupiedSlots().then(data => {
                    this.occupiedSlots = {};
                    
                    data.forEach(item => {
                        if (this.occupiedSlots[item['date']] === undefined) {
                            this.occupiedSlots[item['date']] = [];
                        }
                        this.occupiedSlots[item['date']].push(+item["slot_number"]);
                    });
                });
            },

            showCalendar(data) {
                const { id: tariffId } = data;
                for (let tariff of this.tariffs) {
                    if (tariff.id === tariffId) {
                        this.tariff = tariff;
                        break;
                    }
                }
                this.isCalendarVisible = true;
            },
            handleChooseDate(data) {
                if (data.useTariff) {
                    const tariffId = data.tariff.id;
                    const startDate = data.startDate;
                    const startDateTime = data.startDateTime;
                    const finishDateTime = data.finishDateTime;
                    this.choosenTariffData = {
                        tariffId,
                        startDate,
                        startDateTime,
                        finishDateTime,
                        price: data.tariff.price,
                    };
                    this.isBookConfirmation = true;
                } else {
                    if (isNaN(data.dayNumber)) {
                        return;
                    }
                    const date = new Date(data.year, data.month, data.dayNumber);
                    this.choosenDate = date;
                    this.isBookSlotsVisible = true;
                    this.choosenTariffData = null;
                }
                
                this.isCalendarVisible = false;
            },
            handleChooseSlot(data) {
                if (data.isOccupied) {
                    return;
                }
                this.isBookSlotsVisible = false;
                this.isBookConfirmation = true;
                this.choosenSlot = data;
            },
            async handleSubmitUserForm(id) {
                this.isUserFormVisible = false;
                let result;

                if (this.choosenTariffData) {
                    result = await bookTariff(id, this.choosenTariffData.tariffId, this.choosenTariffData.startDate, this.token);
                } else {
                    result = await bookTimeSlot(id, this.choosenDate, this.choosenSlot, this.token);
                }
                
                if (result.success) {
                    setCookie('must_pay', result.link.split('?')[1].split('&')[1].split('=')[1]);
                    
                    location.href = result.link;
                } else {
                    alert('Что-то пошло не так...');
                }
            },
            handleShowAuthForm() {
                this.isAuthFormVisible = true;
            },
            handleBookConfirm(token) {
                this.token = token;
                this.isBookConfirmation = false;
                this.isUserFormVisible = true;
            },
            clearChoosen() {
                this.tariff = null;
                this.choosenSlot = null;
                this.choosenTariffData = null;
                this.choosenDate = null;
            },
            handleLogout() {
                this.clearChoosen();
                this.userFormData = {};
            },
            handleLogin() {
                this.userFormData = {};
                this._fetchFormData();
                this._checkLastPayed();
            },
            handleClose() {
                this.isCalendarVisible = false;
                this.isBookSlotsVisible = false;
                this.isUserFormVisible = false;
                this.isBookConfirmation = false;
                this.isAuthFormVisible = false;
                this.isHelpVisible = false;
                this.clearChoosen();
            }
        }
    }
</script>

<style scoped>
    .main-screen-container {
        margin: 0 auto;
        max-width: 1000px;
        min-width: 1000px;
        box-sizing: border-box;
    }

    @media (max-width: 1200px) {
        .main-screen-container {
            min-width: 0;
        }
    }

    .info-text {
        border: 1px solid rgb(40, 56, 68);
        border-left-width: 0;
        border-right: 0;
        padding: 30px 20px;
        margin: 30px 0;
        font-weight: 500;
        font-size: 30px;
        color: rgb(0, 21, 36);
    }

    @media (max-width: 400px) {
        .info-text {
            font-size: 24px;
        }
    }

    [data-number="first"].info-text {
        font-weight: 900;
        font-size: 36px;
        border-width: 0;
    }
    [data-number="second"].info-text {
        border-bottom-width: 0;
        margin-bottom: 0;
    }

    [data-number="third"].info-text {
        border-top-width: 0;
        margin-top: 0;
    }

    .cards-screen-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .img {
        text-align: center;
    }

    .img > img {
        max-width: 70vw;
    }

    .cards-screen-container .header {
        font-size: 25px;
        font-weight: 600;
        margin: 30px 0;
        color: rgb(12, 32, 28);
        margin: 40px 0;
        letter-spacing: -2px;
    }
    .btn-help {
        position: fixed;
        top: 2vh;
        right: 2vw;
        cursor: pointer;
        background-color: transparent;
        border-width: 0;
        outline: none;
    }

    .btn-help:hover {
        opacity: 0.8;
    }

    .btn-help svg {
        width: 50px;
        height: 50px;
        opacity: 0.8;
    }

    @media (max-width: 500px) {
        .btn-help svg {
            width: 30px;
            height: 30px;
        }
    }
</style>