<template>
    <div class="container">
        <CloseButton
            :close="close"
        />
        <div class="header">
            Выберите слот для даты: {{ slotDate }}
        </div>
        <div class="body">
            <div
                v-for="slot in slots"
                :key="slot.id"
                class="slot-item"
                :class="{occupied: slot.isOccupied}"
                @click="choose(slot)"
            >
                {{ `${slot.interval} ${slot.id} слот (Цена: ${slot.price})` }}
            </div>
        </div>
    </div>
</template>

<script>
    import CloseButton from './close-button.vue';
    export default {
        components: {
            CloseButton
        },
        props: ['close', 'date', 'choose', 'slot-schemes', 'occupied-slots'],
        data() {
            return {
                slots: [],
            };
        },
        created() {
            this.slots = this.slotSchemes.map((scheme) => {
                const startTime = scheme["start_time"].slice(0, 5);
                const endTime = scheme["end_time"].slice(0, 5);

                const occupiedSlots = this.occupiedSlots[this.slotDateKey] || [];

                return {
                    id: scheme.id,
                    price: scheme.price,
                    interval: `${startTime} - ${endTime}`,
                    isOccupied: occupiedSlots.indexOf(scheme.id) !== -1,
                };
            });
        },
        computed: {
            slotDate() {
                const day = ('0' + this.date.getDate()).slice(-2);
                const month = ('0' + (this.date.getMonth() + 1)).slice(-2);
                const year = this.date.getFullYear().toString();
       
                return `${day}.${month}.${year}`;
            },
            slotDateKey() {
                const d = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 1);
                const _d = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
                return _d.toISOString().split('T')[0];
            }
        }
        // methods: {
        //     choose(slot) {
        //         alert('выбрано: ' + JSON.stringify(slot));
        //     }
        // }
    }
</script>

<style scoped>
    .container {
        position: relative;
        background-color: rgb(249, 243, 231);
        border-radius: 4px;
        border: 1px solid gray;
        box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.25);
        min-width: 40vw;
    }
    
    .header {
        color: rgb(46, 63, 74);
        font-size: 20px;
        margin: 20px 0;
        font-weight: 600;
        margin-bottom: 10px;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .body {
        position: relative;
        border: 1px solid black;
        margin: 20px;
        padding: 20px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border-radius: 6px;
    }

    .slot-item {
        border: 1px solid black;
        border-radius: 6px;
        padding: 6px 12px;
        background-color: white;
        margin: 10px 0;
        min-width: 160px;
        cursor: pointer;
        user-select: none;
    }

    .slot-item:hover {
        background-color: rgb(60, 76, 88);
        color: white
    }

    .slot-item.occupied {
        background-color: rgb(255, 82, 55);
        color: white;
        cursor: default;
    }
</style>