<template>
    <div class="backdrop">
        <div class="container">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {}
</script>

<style scoped>
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(10, 10, 10, 0.65);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .container {
        padding: 25px 0;
        max-height: 100%;
        overflow-y: auto;
        box-sizing: border-box;
    }
</style>