<template>
    <div class="container">
        <CloseButton :close="close" />
        <h3>У вас не оплачена последняя заявка на сумму {{ app.price }} р.</h3>
        <div class="btn-container">
            <OutlinedButton
                title="ОПЛАТИТЬ"
                :on-click="handlePay"
            />
        </div>
    </div>
</template>

<script>
    import OutlinedButton from './outlined-button';
    import CloseButton from './close-button.vue';

    export default {
        components: {
            OutlinedButton,
            CloseButton
        },
        props: ['app'],
        methods: {
            handlePay() {
                window.open(this.app["pay_link"]);
            },
            close() {
                this.$emit('close');
            }
        }
    }
</script>

<style scoped>
    .container {
        background-color: rgb(41, 56, 68);
        padding: 25px;
        border-radius: 15px;
        border: 1px solid black;
        position: relative;
    }
    .btn-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        margin: 0 auto;
        width: 150px;
        text-align: center;
        padding: 0 auto;
    }

    h3 {
        color: white;
        margin-bottom: 50px;
    }
</style>