<template>
    <div class="container" v-show="isLoaded">
        <HomeLink />
        <div class="header1">БЛАГОДАРИМ ВАС!</div>
        <div class="header2">{{ header2 }}</div>
        <div class="header3">{{ header3 }}</div>
        <div class="asking">
            <hr>
            <span>
                СООБЩИТЕ НОМЕР ВАШЕГО ЗАКАЗА НАШЕМУ МЕНЕДЖЕРУ ПО РЕКЛАМЕ: 
                <a class="manager" href="https://t.me/advertmng" target="_blank">@ADVERTMNG</a>
            </span>
            <hr>
        </div>
        <div class="actions">
            <OutlinedButton
                title="НАПИСАТЬ МЕНЕДЖЕРУ ПО РЕКЛАМЕ"
                :on-click="toManager"
            />
            <div style="height: 15px"></div>
            <OutlinedButton
                title="ЗАБРОНИРОВАТЬ ЕЩЕ ОДИН СЛОТ"
                :on-click="handleReset"
                :accent="true"
            />
        </div>
    </div>
</template>

<script>
    import OutlinedButton from './outlined-button';
    import HomeLink from'./home-link';
    import { fetchApplicationData } from '../services/api.js';

    export default {
        components: {
            OutlinedButton,
            HomeLink
        },
        data() {
            return {
                isLoaded: false,
                data: null,
            };
        },
        mounted() {
            const id = +location.search.replace('?', '')
                .split('&').map(item => item.split('='))
                .reduce((acc, el) => ({...acc, [el[0]]: el[1]}), ({}))["inv"];
            if (id) {
                this.fetchData(id);
            }
        },
        methods: {
            fetchData(id) {
                fetchApplicationData(id).then(data => {
                    if (data && data.id) {
                        this.isLoaded = true;
                        this.data = data;
                    }
                });
            },
            formatDate(date) {
                const year = date.getFullYear();
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const day = ('0' + date.getDate()).slice(-2);
                const hour = ('0' + date.getHours()).slice(-2);
                const minute = ('0' + date.getMinutes()).slice(-2);
                return `${year}-${month}-${day} ${hour}:${minute}`;
            },
            toManager() {
                window.open('https://t.me/advertmng');
            },
            handleReset() {
                location.href = '/';
            },
        },
        computed: {
            header2() {
                if (!this.data) return '';
                if (this.data.type === 'slot') {
                    const date = this.data.slots[0].date;
                    const slotId = +this.data['slots'][0]["slot_number"];
                    const scheme = this.data["slot_schemes"][(slotId - 1).toString()];
                    const startTime = scheme["start_time"];
                    const endTime = scheme["end_time"];
                    
                    const interval = `${startTime} - ${endTime}`;
                    return `${slotId} СЛОТ (${interval}) ЗА ВАМИ (${date}) - STANDARD!`.toUpperCase();
                } else {
                    const startDate = new Date(this.data["start_at"]);
                    const endDate = new Date(this.data["end_at"]);
                    endDate.setSeconds(endDate.getSeconds() - 1);
                    const start = this.formatDate(startDate);
                    const end = this.formatDate(endDate);
                    return `ВАШ ПЕРИОД: ${start} - ${end} - ${this.data['tariff_schemes'][+this.data['scheme_id'] - 1]['title']}`.toUpperCase();
                }
            },
            header3() {
                if (!this.data) return '';
                return `Ваш номер заказа: ${this.data.id}`.toUpperCase();
            }
        }
    };
</script>

<style scoped>
    .container {
        background-color: rgb(42, 63, 78);
        border: 1px solid black;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 800px;
        width: auto;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 6px;
    }

    .header1 {
        color: rgb(251, 194, 52);
        font-size: 25px;
        font-weight: 700;
        margin-top: 15px;
    }

    .header2 {
        color: rgb(237, 191, 83);
        font-size: 18px;
        margin-top: 24px;
        box-sizing: border-box;
    }

    .header3 {
        color: rgb(237, 191, 83);
        font-size: 16px;
        margin-top: 18px;
        box-sizing: border-box;
    }

    .asking {
        width: 100%;
        margin: 40px 0;
        margin-bottom: 0;
    }

    .asking > span {
        display: inline-block;
        padding: 16px 5vw;
        color: rgb(228, 237, 240);
        font-size: 14px;
        box-sizing: border-box;
    }

    .asking hr {
        border-color: rgb(228, 237, 240);
        width: 95%;
    }

    .actions {
        margin-top: 8px;
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
    }

    @media (max-width: 350px) {
        .actions {
            width: 86vw;
        }
    }

    @media (max-width: 900px) {
        .header2 {
            padding: 0 30px;
        }

        .asking > span {
            padding: 16px 30px;
        }
    }

    .manager {
        color: rgb(251, 194, 52);
        text-decoration: none;
    }
</style>