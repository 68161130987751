<template>
    <Transition name="alert" appear>
        <div class="container" v-if="isVisible">
            <div class="text">
                Это веб-приложение использует данные, содержащиеся в файлах cookie. 
                Оставаясь на сайте, вы даете согласие на обработку данных, содержащихся в файлах cookie. 
                Если вы не хотите, чтобы эти данные обрабатывались, отключите cookie в настройках браузера. 
                Отказ от использования файлов cookie может привести к тому, что некоторые функции сайта будут вам недоступны.
            </div>
            <div class="spacer"></div>
            <div class="btn-wrapper">
                <button @click="handleClose">Принять</button>
            </div>
        </div>
    </Transition>
</template>

<script>
    export default {
        data() {
            return {
                isVisible: false,
            };
        },
        mounted() {
            if (!localStorage.getItem('cookie_alert')) {
                this.isVisible = true;
            }
        },
        methods: {
            handleClose() {
                this.isVisible = false;
                localStorage.setItem('cookie_alert', '1');
            },
        }
    };
</script>

<style scoped>
    .container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #474747;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;
    }

    .spacer {
        width: 10vw;
    }

    .text {
        color: white;
        padding: 24px;
        text-align: left;
        text-indent: 60px;
    }

    .btn-wrapper {
        padding: 24px;
    }

    button {
        background-color: #72bf44;
        color: white;
        height: 48px;
        font-size: 14px;
        padding: 1px 32px;
        border-radius: 4px;
        cursor: pointer;
        border-width: 0;
        font-size: 16px;
    }

    button:hover {
        background-color: #5b9b35;

    }

    @media (max-width: 700px) {
        .container {
            flex-direction: column;
        }

        .spacer {
            display: .5vh;
        }

        .text {
            padding: 12px 16px;
        }

        .btn-wrapper {
            padding: 16px;
        }
    }

    .alert-enter-from,
    .alert-leave-to { 
        opacity: 0;
    }

    .alert-leave-from,
    .alert-enter-to {
        opacity: 1;
    }

    .alert-enter-active,
    .alert-leave-active {
        transition: opacity 600ms;
    }
</style>