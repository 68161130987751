import { getCookie } from "./cookie";

let prefix = location.origin;

if (prefix.includes('localhost')) {
    prefix = 'http://localhost:9090';
}

export async function activateSession(sid) {
    const resp = await fetch(prefix + '/session', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "sid": sid,
            "screen_width": screen.width,
            "screen_height": screen.height,
            "user_agent": navigator.userAgent,
        }),
    });
    const data = await resp.json();
    return data;
}

export async function fetchTariffs() {
    const resp = await fetch(prefix + '/tariff_scheme');
    return await resp.json();
}

export async function fetchSlotSchemes() {
    const resp = await fetch(prefix + '/slot_scheme');
    return await resp.json();
}

export async function fetchOccupiedSlots() {
    const resp = await fetch(prefix + '/occupied_slot');
    return await resp.json();
}

export async function fetchFormData() {
    const resp = await fetch(prefix + '/user_form', {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Session-Id': getCookie('sid'),
        },
    });
    return await resp.json();
}

export async function fetchApplications(showAll = false) {
    const resp = await fetch(prefix + `/admin/application${showAll ? '?show_all=1' : ''}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Session-Id': getCookie('sid'),
        },
    });
    if (resp.status === 403) {
        throw new Error(403);
    }
    return await resp.json();
}

export async function updateApplication(data) {
    const resp = await fetch(prefix + '/admin/application', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Session-Id': getCookie('sid'),
        },
        body: JSON.stringify(data)
    });
    return await resp.json();
}

export async function removeApplication(dataId) {
    const resp = await fetch(prefix + '/admin/application', {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Session-Id': getCookie('sid'),
        },
        body: dataId.toString()
    });
    return await resp.json();
}

export async function sendUserFormData(fd) {
    
    const body = {};
    for (let pair of fd.entries()) {
        body[pair[0]] = pair[1];
    }

    const resp = await fetch(prefix + '/user_form', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Session-Id': getCookie('sid'),
        },
        body: JSON.stringify(body),
    });
    const data = await resp.json();
    return data;
}

export async function bookTimeSlot(id, day, slot, token) {
    const body = {
        "user_form_id": id,
        "day": day.valueOf(),
        "slot_id": slot.id,
        "token": token
    };

    const resp = await fetch(prefix + '/occupied_slot', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
    });

    return await resp.json();
}

export async function bookTariff(id, tariffId, startDate, token) {
    const _startDate = (new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
    const body = {
        "user_form_id": id,
        "start_date": _startDate,
        "tariff_id": tariffId,
        "token": token
    };

    const resp = await fetch(prefix + '/occupied_tariff', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
    });

    return await resp.json();
}

export async function login(body) {
    const resp = await fetch(prefix + '/auth', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Session-Id': getCookie('sid'),
        },
        body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
}

export async function logout() {
    const resp = await fetch(prefix + '/auth', {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Session-Id': getCookie('sid'),
        },
    });

    const data = await resp.json();
    return data;
}


export async function fetchApplicationData(id) {
    const resp = await fetch(prefix + `/application?id=${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Session-Id': getCookie('sid'),
            'Tg-Id': getCookie('user_tg_id'),
        },
    });

    const data = await resp.json();
    return data;
}


export async function fetchTokens() {
    const resp = await fetch(prefix + `/admin/token`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Session-Id': getCookie('sid'),
        },
    });

    const data = await resp.json();
    return data;
}


export async function createToken(name, contacts, text) {
    const body = { name, contacts, text };
    const resp = await fetch(prefix + `/admin/token`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Session-Id': getCookie('sid'),
        },
        body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
}


export async function checkToken(token) {
    const resp = await fetch(prefix + `/token?token=${token}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Session-Id': getCookie('sid'),
        },
    });

    const data = await resp.json();
    return data;
}


