<template>
    <div class="container">
        <div class="tabbar">
            <button @click="handleToggleInDetail" class="in-detail">
                {{ inDetail ? 'Скрыть удаленное' : 'Показать удаленное' }}
            </button>
            <button class="tokens" @click="isTokensVisible = true;">
                Tокены
            </button>
            <button class="help" @click="isHelpVisible = true;">
                &quest;
            </button>
        </div>
        <ModalBackdrop v-if="isHelpVisible">
            <AdminHelp :close="handleCloseHelp" />
        </ModalBackdrop>
        <div class="body" v-if="!isForbidden && isLoaded">
            <table>
                <thead>
                    <th>ID</th>
                    <th>&nbsp;Тип&nbsp;</th>
                    <th>Начало действия</th>
                    <th>Окончание действия</th>
                    <th>Статус заявки</th>
                    <th>Ссылка на отмененную заявку</th>
                    <th>Оплачено</th>
                    <th>ВП</th>
                    <th>Ссылка на пост в Телеграмме</th>
                    <th>Ник рекламодателя в ТГ</th>
                    <th>Стоимость рекламы</th>
                    <th>Ссылка на ТГ канал или сайт рекламодателя</th>
                    <th>Форма собственности ООО/ИП/ФИО</th>
                    <th>Название компании или ФИО рекламодателя</th>
                    <th>Email рекламодателя</th>
                    <th>Дата создания</th>
                    <th>&#128190;</th>
                    <!--<th>&#x1F9FA;</th>-->
                </thead>
                <tbody>
                    <tr 
                        v-for="(app, index) in applications"
                        :key="app.id"
                        :id="'app_item_' + app.id"
                        :class="{[app.status]: true, 'choosing': !!choosingCancelledApplication && choosingCancelledApplication.id === app.id, light: lightOriginal && lightOriginal.id === app.id}"
                        @click="handleApplicationClick(app)"
                    >
                        <td>{{ app.id }}</td>
                        <td>{{ formatApplicationType(app) }}</td>
                        <td>{{ formatDate(app.start_at) }}</td>
                        <td>{{ formatDate(app.end_at, true) }}</td>
                        <td>
                            <select v-model="app.status">
                                <option value="booked">Забронировано</option>
                                <option value="reserved">Размещено в бот</option>
                                <option value="created">Реклама вышла</option>
                                <option value="closed">Рекламу можно удалить</option>
                                <option value="removed">Удалено</option>
                                <option value="cancelled">Отменена</option>
                            </select>
                        </td>
                        <td>
                            <div>
                                <a class="original-app" :href="app.original ? `#app_item_${app.original}` : null" @click="handleClickSearchOriginal(app)">
                                    {{ app.original ? `ID: ${app.original}` : 'Отсутствует' }}
                                </a>
                            </div>
                            <button class="choice-original" @click="handleChoiceOriginal(app)">
                                {{this.choosingCancelledApplication && this.choosingCancelledApplication.id === app.id ? 'Отменить' : 'Выбрать'}}
                            </button>
                            <button class="choice-original" @click="handleAnnulOriginal()" v-if="app.original !== null && choosingCancelledApplication !== null">
                                Аннулировать
                            </button>
                        </td>
                        <td>
                            {{ app.is_payed ? 'Да' : 'Нет' }}
                        </td>
                        <td>
                            <select v-model="app.is_interchange">
                                <option :value="false">Нет</option>
                                <option :value="true">Да</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" v-model="app.post_link" placeholder="Ссылка на рекламу"><br>
                            <a :href="app.post_link" target="_blank" :style="{opacity: app.post_link ? 1.0 : 0.4}">Перейти</a>
                        </td>
                        <td>{{ app.username }}</td>
                        <td>{{ app["is_interchange"] ? "-" : app.price }}</td>
                        <td><a :href="app.link" target="_blank">Ссылка</a></td>
                        <td>{{ formatUserStatus(app.user_status) }}</td>
                        <td>{{ app.name }}</td>
                        <td>{{ app.email }}</td>
                        <td>{{ formatDate(app.created_at, false, true) }}</td>
                        <td><button class="action" @click="_updateApplication(index)">Сохранить</button></td>
                        <!--<td><button class="action" @click="_removeApplication(index)">Удалить</button></td>-->
                    </tr>
                </tbody>
            </table>
            <ModalBackdrop v-if="isTokensVisible">
                <AdminTokens :close="handleCloseTokens"/>
            </ModalBackdrop>
        </div>
        <div class="forbidden" v-if="isForbidden">
            <div>Доступ запрещен</div>
        </div>
    </div>
</template>

<script>
    import { fetchApplications, updateApplication, removeApplication } from '../../services/api.js';
    import ModalBackdrop from '../modal-backdrop.vue';
    import AdminTokens from '../admin-tokens.vue';
    import AdminHelp from '../admin-help.vue';

    export default {
        components: {
            AdminTokens,
            AdminHelp,
            ModalBackdrop,
        },
        data() {
            return {
                applications: [],
                tariffs: [],
                isForbidden: false,
                isLoaded: false,
                choosingCancelledApplication: null,
                isHelpVisible: false,
                lightOriginal: null,
                inDetail: location.search.includes("show_all=1"),
                isTokensVisible: false,
            };
        },
        created() {
            this._fetchApplications();
        },
        methods: {
            _fetchApplications() {
                fetchApplications(this.inDetail).then(response => {
                    this.applications = response.data;
                    this.tariffs = response.tariffs;
                    this.isLoaded = true;
                }).catch(error => {
                    if (error.message == '403') {
                        this.isForbidden = true;
                        setTimeout(() => location.href = '/', 2000);
                    }
                });
            },
            async _updateApplication(index) {
                const application = this.applications[index];
                const result = await updateApplication(application);
                if (!result.success) {
                    alert(result.error);
                }
            },
            async _removeApplication(index) {
                if (confirm('Удалить заявку? Вся информация о ней будет стерта и возможности восставноления не будет!')) {
                    const result = await removeApplication(this.applications[index].id);
                    if (!result['success']) {
                        alert('Что-то пошло не так...');
                    } else {
                        this.applications.splice(index, 1);
                    }
                }
                
            },
            formatDate(dateString, finish = false, currentTimezone = false) {
                const date = new Date(dateString);
                if (currentTimezone) {
                    date.setMinutes(date.getMinutes() - (new Date()).getTimezoneOffset());
                }
                if (finish) {
                    date.setSeconds(date.getSeconds() - 1);
                }
                const year = date.getFullYear();
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const day = ('0' + date.getDate()).slice(-2);
                const hours = ('0' + date.getHours()).slice(-2);
                const minutes = ('0' + date.getMinutes()).slice(-2);
                return `${year}-${month}-${day} ${hours}:${minutes}`;
            },
            formatApplicationType(app) {
                const type = app.type;
                const id = app["scheme_id"];
                if (type === 'slot') {
                    return `Слот № ${id}`;
                } else {
                    return `Тариф \n ${this.tariffs[id - 1].title}`;
                }
            },
            formatUserStatus(status) {
                return {
                    legal_entiry: 'Юр. лицо',
                    individual_entrepreneur: 'ИП',
                    self_employed: 'Самозанятый',
                    natural_person: 'Физ. лицо'
                }[status];
            },
            handleChoiceOriginal(app) {
                if (this.choosingCancelledApplication && this.choosingCancelledApplication.id === app.id) {
                    this.choosingCancelledApplication = null;
                } else {
                    this.choosingCancelledApplication = app;
                }
            },
            handleAnnulOriginal() {
                this.choosingCancelledApplication.original = null;
                this.choosingCancelledApplication = null;
            },
            handleApplicationClick(app) {
                if (this.choosingCancelledApplication !== null && app.id !== this.choosingCancelledApplication.id) {
                    if (app.status !== 'cancelled') {
                        alert('Данная заявка не была отменена. Измените ее статус на отмененный и сохраните.');
                        return;
                    }
                    this.choosingCancelledApplication.original = app.id;
                    this.choosingCancelledApplication = null;
                }
            },
            handleClickSearchOriginal(app) {
                if (!app.original) return;
                const apps = this.applications;
                for (let i = 0; i < apps.length; i++) {
                    if (apps[i].id === app.original) {
                        this.lightOriginal = apps[i];
                        setTimeout(() => this.lightOriginal = null, 500);
                        break;
                    }
                }
            },
            handleToggleInDetail() {
                if (this.inDetail) {
                    location.href = '/admin';
                } else {
                    location.href = '/admin?show_all=1';
                }
            },
            handleCloseTokens() {
                this.isTokensVisible = false;
            },
            handleCloseHelp() {
                this.isHelpVisible = false;
            }
        },
    };
</script>

<style scoped>
    .tabbar {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: relative;
        top: -30px;
        padding: 0 5px;
    }

    .tabbar button.in-detail {
        background-color: transparent;
        border-width: 0;
        margin-right: 15px;
        color: blue;
        cursor: pointer;
        font-size: 20px;
    }

    .tabbar button.help {
        border-radius: 50%;
        font-size: 20px;
        font-weight: bold;
        border-width: 1px;
        cursor: pointer;
        background-color: transparent;
        width: 35px;
    }

    .tabbar button.tokens {
        cursor: pointer;
        margin-right: 15px;
        border-radius: 6px;
        outline: none;
        border: 1px solid black;
    }

    .tabbar button:hover {
        opacity: 0.75;
    }

    table {
        width: 100%;
        table-layout: fixed;
        border: 1px solid;
        box-sizing: border-box;
        table-layout: auto;
    }

    th, td {
        border: 1px solid;
        box-sizing: border-box;
        width: auto;
        padding: 1rem 0.4rem;
    }

    .container {
        min-height: calc(100vh - 330px);
    }

    .body {
        margin: 0 auto;
        overflow-y: auto;
    }

    tr {
        transition: background-color 500ms, box-shadow 500ms;
    }

    tr.booked {
        background-color: rgb(158, 153, 161);
        color: white;
    }

    tr.reserved {
        background-color: yellow;
        color: black;
    }

    tr.created {
        background-color: rgb(59, 231, 130);
        color: black;
    }

    tr.closed {
        background-color: #e07565;
        color: blue;
    }

    tr.removed {
        background-color: black;
        color: white;
    }

    @keyframes choosing {
        from {background-color: rgb(204, 198, 223);}
        to {background-color: rgb(7, 8, 27);}
    }
    tr.choosing {
        animation-name: choosing;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    tr.light {
        background-color: orange;
        box-shadow: 0 0 8px 2px orange;
    }

    .forbidden {
        color: red;
        font-size: 60px;
        font-weight: 700;
        width: 100%;
        height: calc(100vh - 330px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    select, input[type="text"] {
        padding: 4px 2px;
        border-radius: 4px;
    }

    button.action {
        background-color: brown;
        color: white;
        cursor: pointer;
        border-radius: 10px;
        padding: 5px 6px;
    }
    
    a {
        color: inherit;
        font-weight: bold;
        text-decoration: none;
    }

    button.choice-original {
        background-color: transparent;
        border-width: 0;
        cursor: pointer;
        color: inherit;
        opacity: 0.9;
    }

    button.choice-original:hover {
        opacity: 0.6;
    }

    a.original-app {
        font-weight: normal;
    }

    a.original-app[href]:hover {
        opacity: 0.6;
    }
</style>