<template>
    <button class="btn-home" @click="toHome">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 256 256" xml:space="preserve">
            <defs>
            </defs>
            <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                <polygon points="75.96,30.96 75.96,13.34 67.26,13.34 67.26,22.26 45,0 0.99,44.02 7.13,50.15 45,12.28 82.88,50.15 89.01,44.02 " style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(138,149,151); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
                <polygon points="45,20 14.04,50.95 14.04,90 35.29,90 35.29,63.14 54.71,63.14 54.71,90 75.96,90 75.96,50.95 " style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(138,149,151); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
            </g>
        </svg>
    </button>
</template>

<script>
    export default {
        methods: {
            toHome() {
                location.href = '/';
            }
        }
    }
</script>

<style scoped>
    .btn-home {
        background-color: transparent;
        border: 1px solid transparent;
        position: fixed;
        top: 2vh;
        left: 2vw;
        cursor: pointer;
        outline: none;
    }

    .btn-home:hover {
        opacity: 0.8;
    }

    .btn-home svg {
        width: 50px;
        height: 50px;
    }

    @media (max-width: 500px) {
        .btn-home svg {
            width: 30px;
            height: 30px;
        }
    }
</style>