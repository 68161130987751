<template>
    <div class="panel-container">
        <div class="card-item" v-for="tariff in tariffs" :key="tariff.id">
            <TariffCard
                :id="tariff.id"
                :title="tariff.title"
                :price="tariff.price"
                :slot-amount="tariff.slot_amount"
                :start-day="tariff.start_day"
                :start-slot="tariff.start_slot"
                :feed-hours-amount="tariff.feed_hours_amount"
                :is-standard="false"
                v-bind="$attrs"
            />
        </div>
    </div>
</template>

<script>
import TariffCard from './tariff-card.vue';
export default {
    props: ["tariffs"],
    components: {
        TariffCard,
    },
    data() {
        return {

        };
    },
    methods: {

    }
}
</script>

<style scoped>
    .panel-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        overflow-wrap: break-word;
    }

    .card-item {
        margin-bottom: 10px;
    }

    @media (max-width: 1100px) {
        .panel-container {
            flex-wrap: wrap;
        }
    }
</style>