<template>
    <button class="close-btn" @click="close">&#10005;</button>
</template>

<script>
    export default {
        props: ["close"]
    };
</script>


<style scoped>
    .close-btn {
        position: absolute;
        top: -30px;
        right: 5px;
        font-size: 20px;
        background-color: transparent;
        border-width: 0;
        outline: none;
        color: white;
        cursor: pointer;
    }

    .close-btn:hover {
        opacity: 0.5;
    }
</style>