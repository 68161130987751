<template>
    <div class="tariff-card" :class="{standard: isStandard}">
        <div class="header"> {{ cardTitle }} </div>
        <div class="conditions">
            <ul>
                <li>{{ topTitle }}</li>
                <li>{{ feedTitle }}</li>
            </ul>
        </div>
        
        <div class="price">
            {{ priceTitle }}
        </div>
        <div class="btn-container">
            <OutlinedButton
                title="ОПЛАТИТЬ"
                :on-click="handleClick"
            />
        </div>
    </div>

</template>

<script>
    import OutlinedButton from './outlined-button';

    export default {
        props: [
            "id",
            "title",
            "price",
            "slot-amount",
            "start-day",
            "start-slot",
            "feed-hours-amount",
            "is-standard"
        ],
        components: {
            OutlinedButton
        },
        data() {
            return {
                y: 99
            };
        },
        methods: {
            _hours(number) {
                if (number === 1) {
                    return "час";
                } else if (number > 1 && number < 5) {
                    return "часа";
                } else {
                    return "часов";
                }
            },
            handleClick() {
                this.$emit('tariff-pay', { id : this.id || null });
            },
            hoursWord(hours) {
                const mod = hours % 10;
                if (mod === 1) {
                    return "час";
                } else if (mod >= 2 && mod <= 4 ) {
                    return "часа";
                } else {
                    return "часов";
                }
            },
        },
        computed: {
            cardTitle() {
                return this.title.toUpperCase();
            },
            topTitle() {
                const hours = this['slotAmount'] * 4;
                return `${hours} ${this.hoursWord(hours)} в топе`;
            },
            feedTitle() {
                const hours = this.feedHoursAmount;
                return `${hours} ${this.hoursWord(hours)} в ленте`;
            },
            priceTitle() {
                return `Стоимость: ${this.price} РУБ`;
            }
            
        }
    }
</script>

<style scoped>
    .tariff-card {
        display: inline-block;
        background-color: rgb(41, 56, 68);
        padding: 20px 18px 15px 18px;
        border-radius: 4px;
        box-sizing: border-box;
        width: 230px;
    }

    .tariff-card.standard {
        width: 100%;
    }

    .header {
        color: rgb(254, 189, 50);
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 1.5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .conditions {
        display: inline-block;
        color: rgb(159, 180, 187);
        font-size: 14px;
        margin: 12px 0;
        text-align: center;
    }

    .conditions ul {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .price {
        color: white;
        font-size: 16px;
        margin-bottom: 25px;
        font-weight: 600;
    }

    ul {
        list-style-position: inside;
        padding-left: 0;
        text-align: left;
    }

    .btn-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        margin: 0 auto;
        width: 150px;
        text-align: center;
        padding: 0 auto;
    }

    @media (max-width: 1200px) {
        .tariff-card {
            min-width: 190px;
            min-height: 270px;
        }

        .header {
            white-space: normal;
            text-overflow: clip;
            overflow: visible;
            word-spacing:9999px;
        }

        .btn-container {
            width: 120px;
        }
    }
</style>