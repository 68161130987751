<template>
    <div class="container">
        <CloseButton :close="close"/>
        <div class="text">
            <p>
                Здесь вы можете приобрести рекламу на телеграм канале python_tasks, зарезервировав временные слоты или тариф. Каждый временной слот позволяет находиться в топе вашему рекламному банеру 4 часа. 
                Тариф - этот набор последовательных слотов объдиненных вместе по более низкой цене, по сравнению с приобретением слотов независимо. В этом случае ваш рекламный банер может находиться в топе 24, 48 или 72 часа. 
            </p>
            <p>
                Данный веб-сайт создан с целью автоматизировать платежи клиентов, желающих разместить рекламу. Оплата осуществляется через платежную систему Робокасса. Передача рекламного контента или рекламного баннера осуществляется клиентом напрямую путём отправления сообщения по адресу @advertmng.
                После проверки рекламного сообщения, менеджер по рекламе предоставляет рекламодателю уникальный токен, который необходимо сохранить и ввести на этом сайте в процессе оформления заказа на рекламу. После оплаты рекламного слота, сообщите полученный номер заказа менеджеру по рекламе. 
            </p>
            <p>
                Телеграм канал @python_tasks специализируется на задачах с выбором вариантов ответов  (язык программирования Python), а также задачах по машинному обучению.
                Каждая новая задача на канале @python_tasks выходит в 12 часов дня по московскому времени и только по рабочим дням.
            </p>
            <p>Будем рады сотрудничеству!</p>
        </div>
    </div>
</template>

<script>
    import CloseButton from './close-button.vue';

    export default {
        components: {
            CloseButton
        },
        props: ["close"]
    };
</script>

<style scoped>
    .container {
        background-color: white;
        padding: 10px 20px;
        border: 1px solid black;
        border-radius: 15px;
        position: relative;
        max-width: 60vw;
        max-height: 90vh;
    }

    @media (max-width: 500px) {
        .container {
            max-width: 95vw;
        }
    }

    .text {
        overflow-y: auto;
        max-height: 90vh;
    }

    .text p {
        text-align: left;
        text-indent: 40px;
        font-size: 16px;
    }

    .text p:nth-last-child(2) {
        font-size: 14px;
        font-weight: 700;
    }

    .text p:last-child {
        margin-top: 20px;
        text-align: center;
        text-indent: 0;
    }
</style>