<template>
    <button @click="onClick" :class="{'accent': accent}">
        {{ title }}
    </button>
</template>

<script>
    export default {
        props: ['title', 'onClick', 'accent'],
    }
</script>

<style scoped>
    button {
        display: block;
        padding: 6px 0;
        width: auto;
        box-sizing: border-box;
        background-color: transparent;
        border: 2px solid rgb(254, 189, 50);
        color: rgb(254, 189, 50);
        cursor: pointer;
        font-weight: 500;
        font-size: 15px;
        border-radius: 3px;
    }

    button:hover {
        background-color: rgb(254, 189, 50);
        color: rgb(41, 56, 68);
    }

    button.accent {
        background-color: rgb(254, 189, 50);
        color: rgb(41, 56, 68);
    }
</style>