<template>
    <div class="container">
        <CloseButton
            :close="close"
        />
        <div class="title" v-html="title"></div>
        <form @submit.prevent="handleSubmit">
            <label
                @click="handleClickInput"
                @mousemove="handleMouseMoveLabel"
                @mouseenter="handleMouseMoveLabel"
                @mouseleave="handleMouseLeaveLabel"
            >
                <input 
                    type="text"
                    placeholder="Токен для размещения рекламы*"
                    name="token"
                    v-model="token"
                    @input="error = ''"
                    @mousemove="handleMouseMoveInput"
                >
            </label>
            <div class="error">{{ error }}</div>
            <button :disabled="disabled" :class="{disabled}">
                {{ btnText }}
            </button>
        </form>
    </div>
</template>

<script>
    import { checkToken } from '../services/api';
    import CloseButton from './close-button.vue';

    export default {
        components: {
            CloseButton
        },
        props: ["choosen-date", "choosen-slot", "choosen-tariff-data", "close"],
        data() {
            return {
                token: '',
                isChecking: false,
                error: "",
            };
        },
        methods: {
            async handleSubmit() {
                this.error = "";
                this.isChecking = true;
                try {
                    const result = await checkToken(this.token);
                    if (!result.success) {
                        this.error = result.error;
                    } else {
                        this.$emit('confirm', this.token);
                    }
                } catch(err) {
                    console.error(err);
                    this.error = "Серверная ошибка";
                }
                this.isChecking = false;
            },
            formatDate(date) {
                const day = ('0' + date.getDate()).slice(-2);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear().toString();
                const dateString = `${day}.${month}.${year}`;
                return dateString;
            },
            formatDateTime(dateTime) {
                const hours = ('0' + dateTime.getHours()).slice(-2);
                const minutes = ('0' + (dateTime.getMinutes())).slice(-2);
                const seconds = ('0' + (dateTime.getSeconds())).slice(-2);

                const timeString = `${this.formatDate(dateTime)} ${hours}:${minutes}:${seconds}`;
                return timeString;
            },
            handleClickInput(event) {
                if (event.offsetY > event.target.offsetHeight) {
                    window.open("https://t.me/advertmng");
                }
            },
            handleMouseMoveLabel(event) {
                if (event.offsetY > event.target.offsetHeight) {
                    if (!event.target.classList.contains('hover')) {
                        event.target.classList.add('hover');
                    }
                } else {
                    if (event.target.classList.contains('hover')) {
                        event.target.classList.remove('hover');
                    }
                }
            },
            handleMouseMoveInput(event) {
                if (event.target.parentNode.classList.contains('hover')) {
                    event.target.parentNode.classList.remove('hover');
                }
            },
            handleMouseLeaveLabel(event) {
                if (event.target.classList.contains('hover')) {
                    event.target.classList.remove('hover');
                }
            }
        },
        computed: {
            title() {
                if (this.choosenTariffData) {
                    const startDateTime = this.formatDateTime(this.choosenTariffData.startDateTime);
                    const finishDateTime = this.formatDateTime(this.choosenTariffData.finishDateTime);
                    return `Подтвердите временной интервал для бронирования:<br>${startDateTime} - ${finishDateTime} (Цена: ${this.choosenTariffData.price})`;
                } else {
                    const dateString = this.formatDate(this.choosenDate);
                    const interval = this.choosenSlot.interval;
                    return `Подтвердите слот (${interval}) для даты: ${dateString} (Цена: ${this.choosenSlot.price})`;
                }
            },
            btnText() {
                return this.isChecking ? "Подождите..." : "Подтвердить";
            },
            disabled() {
                return this.token.length !== 64 || !/^[a-fA-F0-9]+$/.test(this.token) || this.isChecking;
            }
        }
    };
</script>


<style scoped>
    .container {
        background-color: rgb(249, 241, 230);
        border: 1px white solid;
        border-radius: 4px;
        padding: 15px;
        position: relative;
        padding-top: 0;
        min-width: 60vw;
    }

    .title {
        font-size: 20px;
        margin: 24px 0;
        font-size: 20px;
        font-weight: 700;
        color: rgb(41, 56, 68);
        letter-spacing: 0.5px;
    }

    form {
        background-color: white;
        border: 1px solid black;
        border-radius: 1px solid black;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    form label {
        position: relative;
        width: 70%;
    }

    form div.error {
        margin: 16px 0;
        color: red;
        font-size: 14px;
    }

    @media (max-width: 450px) {
        form label {
            width: 96%;
        }
    }

    form input {
        width: 100%;
        font-size: 15px;
        padding: 3px 4px;
    }

    form label::after {
        content: "*Должен быть получен у администратора @advertmng";
        position: absolute;
        top: 100%;
        left: 0;
        user-select: contain;
        font-size: 11px;
        cursor: pointer;
    }

    form label.hover::after {
        color: rgb(130, 130, 191);
    }

    form button {
        border: 1px solid rgb(41, 56, 68);
        border-radius: 4px;
        cursor: pointer;
        background-color: white;
        padding: 4px 20px;
        font-size: 15px;
        user-select: none;
    }

    form button:hover {
        background-color: rgb(41, 56, 68);
        color: white;
    }

    .disabled {
        pointer-events: none;
        border-color: rgba(41, 56, 68, 0.5);
    }
</style>