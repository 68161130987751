import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";


import App from './App';
import MainScreen from './components/screens/main-screen';
import AdminScreen from './components/screens/admin-screen';
import ContactDetails from './components/screens/contact-details';
//import TermsOfService from './components/screens/terms-of-service';


const routes = [
    { path: '/', component: MainScreen },
    { path: '/admin', component: AdminScreen },
    { path: '/contact_details', component: ContactDetails },
    //{ path: '/terms_of_service', component: TermsOfService },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});




const app = createApp(App);

app.use(router);
app.mount('#app')
