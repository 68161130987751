<template>
    <div class="container">
        <CloseButton
            :close="close"
        />
        <div class="title">
            Введите логин и пароль, полученные в нашем Telegram боте
        </div>
        <form @submit.prevent="login">
            <label>
                <span>Логин<span class="star">*</span></span>
                <input type="text" name="username">
            </label>
            <label>
                <span>Пароль<span class="star">*</span></span>
                <input type="password" name="password">
            </label>

            <button>Авторизоваться</button>
            <div class="error" v-show="error">
                {{ error }}
            </div>
        </form>
        <div class="instruction">
            <div>Где взять логин и пароль?</div>
            <div>1. В телеграмм найдите и запустите нашего бота: <a class="tg-bot" href="https://t.me/reg_payment_bot" target="_blank">@reg_payment_bot</a></div>
            <div>2. В меню бота введите команду /auth</div>
            <div>3. Бот выдаст логин и пароль</div>
            <button @click="toTelegram">Открыть Telegram бота</button>
        </div>
    </div>
</template>


<script>
    import { setCookie } from '@/services/cookie';
    import { login } from '../services/api';

    import CloseButton from './close-button.vue';

    export default {
        components: {
            CloseButton
        },
        props: ["close"],
        data() {
            return {
                error: null
            };
        },
        methods: {
            async login(event) {
                const fd = new FormData(event.target);
                const obj = Object.fromEntries(fd);
                const response = await login(obj);
                if (!response['success']) {
                    this.error = "Неверное имя пользователя или пароль!";
                } else {
                    const userTgId = response['user_tg_id'];
                    setCookie('user_tg_id', userTgId);
                    this.$emit('login');
                    this.close();
                }
            },
            toTelegram() {
                window.open('https://t.me/reg_payment_bot');
            },
        }
    }
</script>

<style scoped>
    .container {
        position: relative;
        background-color: rgb(249, 241, 230);
        border-radius: 8px;
        border: 1px solid white;
        box-sizing: border-box;
        padding: 15px;
        padding-top: 0;
    }

    .title {
        color: rgb(40, 56, 68);
        font-size: 18px;
        font-weight: 700;
        margin: 20px 50px;
    }

    .error {
        margin-top: 15px;
        color: red;
    }

    form {
        border: 1px solid rgb(65, 78, 89);
        padding: 20px;
        border-radius: 4px;
    }

    form label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    form label > span {
        font-size: 13px;
        color: rgb(47, 45, 42);
    }

    form input {
        width: 100%;
        box-sizing: border-box;
        padding: 4px 8px;
        font-size: 16px;
        border-radius: 4px;
        border: 1px solid rgb(221, 221, 221);
        color: rgb(40, 56, 68);
    }

    form label:nth-of-type(2) {
        margin-top: 10px;
    }

    form button {
        margin: 14px auto;
        margin-bottom: 0;
        background-color: rgb(40, 56, 68);
        border-radius: 4px;
        color: white;
        cursor: pointer;
        padding: 4px 20px;
        font-size: 16px;
        letter-spacing: 0.1ch;
    }

    form button:hover {
        background-color: white;
        color: black;
    }

    .star {
        color: red;
    }

    .instruction {
        margin-top: 20px;
        background-color: white;
        border: 1px dotted rgb(99, 111, 121);
        border-radius: 4px;
    }

    .instruction > div {
        margin: 15px 0;
        color: rgb(60, 60, 60);
    }

    .instruction > button {
        margin: 12px 0;
        border: 1px solid rgb(29, 29, 29);
        background-color: white;
        padding: 4px 20px;
        border-radius: 4px;
        cursor: pointer;
    }

    .instruction > button:hover {
        background-color: rgb(29, 29, 29);
        color: white;
    }

    @media (max-width: 630px) {
        .instruction > div {
            margin: 8px 0;
        }
    }
    .tg-bot {
        color: blue;
        text-decoration: none;        
    }
</style>